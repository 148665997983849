import React, { useState, useReducer } from "react"
import useResizeObserver from "use-resize-observer/polyfilled"
import PropTypes from "prop-types"

import RefreshIcon from "@material-ui/icons/Refresh"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import InfoIcon from "@material-ui/icons/Info"

import {
  Typography,
  IconButton,
  makeStyles,
  Tooltip,
  Link,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  FormGroup
} from "@material-ui/core"
import Plotter from "./Plotter"
import dayViewProcessor from "./DayViewProcessing"
import weekViewProcessor from "./WeekViewProcessing"
import Header from "./Header"
import { mapsHref } from "./helpers"
import map from "lodash/map"

function selectProcessor(viewMode) {
  switch (viewMode) {
    case "7d":
      return weekViewProcessor
    case "24h":
      return dayViewProcessor
    default:
      return undefined
  }
}

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  plotter: {
    fontFamily: "Roboto"
  },
  highLink: {
    fontFamily: "Roboto",
    height: 38,
    verticalAlign: "middle"
  },
  routeNamesDiv: {
    height: 64,
    display: "grid",
    gridTemplateColumns: "60px 1fr"
  },
  routeName: {
    verticalAlign: "middle",
    paddingRight: "16px",
    height: 32,
    lineHeight: "32px"
  },
  routeLabel: {
    verticalAlign: "middle",
    paddingRight: "16px",
    height: 32,
    lineHeight: "32px",
    textAlign: "right"
  },
  topBarControlGroup: {
    border: "1px solid #ddd",
    borderRadius: 4,
    paddingLeft: 8,
    margin: 4
  },
  smallLabel: {
    fontSize: "small"
  },
  controls: {
    display: "flex"
  }
}))

const daysOfWeek = [
  { key: "mon", text: "Monday" },
  { key: "tue", text: "Tuesday" },
  { key: "wed", text: "Wednesday" },
  { key: "thu", text: "Thursday" },
  { key: "fri", text: "Friday" },
  { key: "sat", text: "Saturday" },
  { key: "sun", text: "Sunday" }
]

const initialDayFilter = {
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
  sun: true
}

const SmallLabel = ({ children }) => {
  const classes = useStyles({})
  return <Typography className={classes.smallLabel}>{children}</Typography>
}

const DayCheckBoxes = ({ daysFilter, updateDaysFilter }) => {
  const classes = useStyles({})
  return (
    <FormControl component="fieldset" className={classes.topBarControlGroup}>
      <FormLabel component="legend">Show:</FormLabel>
      <FormGroup row>
        {map(daysOfWeek, dayOfWeek => (
          <FormControlLabel
            key={dayOfWeek.key}
            control={
              <Checkbox
                checked={daysFilter[dayOfWeek.key]}
                color="primary"
                size="small"
                onChange={() =>
                  updateDaysFilter({ [dayOfWeek.key]: event.target.checked })
                }
                className={classes.topBarControl}
              />
            }
            label={<SmallLabel>{dayOfWeek.text}</SmallLabel>}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export const TrackedRouteDataView = ({
  data,
  trackedRoute,
  onClickRefresh
}) => {
  const { ref, width = 1, height = 1 } = useResizeObserver()
  const [viewMode, setViewMode] = useState("24h")
  const classes = useStyles({})
  const [inboundOrOutbound, setInboundOrOutbound] = useState("outbound")
  const [daysFilter, updateDaysFilter] = useReducer(
    (map, action) => ({ ...map, ...action }),
    initialDayFilter
  )
  const fromText =
    inboundOrOutbound === "outbound"
      ? trackedRoute.origin
      : trackedRoute.destination
  const toText =
    inboundOrOutbound === "outbound"
      ? trackedRoute.destination
      : trackedRoute.origin

  const trackingEndedText = trackedRoute.trackUntil ? (
    <div style={{ display: "flex" }}>
      <InfoIcon />
      <SmallLabel>Data is no longer being collected for this route.</SmallLabel>
    </div>
  ) : (
    undefined
  )

  return (
    <>
      <Header title="Traffic Tracking">
        <div className={classes.routeNamesDiv}>
          <Typography className={classes.routeLabel}>From:</Typography>
          <Typography className={classes.routeName}>{fromText}</Typography>
          <Typography className={classes.routeLabel}>To:</Typography>
          <Typography className={classes.routeName}>{toText}</Typography>
        </div>
        <Tooltip title="Swap from/to">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() =>
              setInboundOrOutbound(
                inboundOrOutbound === "inbound" ? "outbound" : "inbound"
              )
            }
          >
            <SwapVertIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Show in Google Maps">
          <Link color="inherit" href={mapsHref(fromText, toText)}>
            <IconButton edge="start" color="inherit">
              <OpenInNewIcon />
            </IconButton>
          </Link>
        </Tooltip>

        <Tooltip title="Refresh the data">
          <IconButton edge="start" color="inherit" onClick={onClickRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Header>
      <div className={classes.controls}>
        <FormControl
          component="fieldset"
          className={classes.topBarControlGroup}
        >
          <FormLabel component="legend">View</FormLabel>
          <RadioGroup
            row
            name="viewMode"
            value={viewMode}
            onChange={event => setViewMode(event.target.value)}
          >
            <FormControlLabel
              value="24h"
              control={<Radio color="primary" size="small" />}
              label={<SmallLabel>24-hour</SmallLabel>}
            />
            <FormControlLabel
              value="7d"
              control={<Radio color="primary" size="small" />}
              label={<SmallLabel>7-day</SmallLabel>}
            />
          </RadioGroup>
        </FormControl>
        <DayCheckBoxes
          daysFilter={daysFilter}
          updateDaysFilter={updateDaysFilter}
        />
      </div>
      <div ref={ref} className={classes.plotter}>
        <Plotter
          width={width}
          data={data}
          processor={selectProcessor(viewMode)}
          inboundOrOutbound={inboundOrOutbound}
          daysFilter={daysFilter}
        />
      </div>
      {trackingEndedText}
    </>
  )
}

TrackedRouteDataView.propTypes = {
  data: PropTypes.object,
  trackedRoute: PropTypes.object,
  onClickRefresh: PropTypes.func
}
