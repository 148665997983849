import React, { useState } from "react"
import {
  Toolbar,
  AppBar,
  Typography,
  makeStyles,
  Tooltip,
  IconButton
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { Redirect } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  appbar: {
    marginBottom: 10
  },
  headerLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    flexGrow: 1
  }
}))

export default ({ title, children, isHome }) => {
  const classes = useStyles({})
  const [redirectToHome, setRedirectToHome] = useState(false)

  if (!isHome && redirectToHome) {
    return <Redirect to="/" />
  }

  var aboutLink = null
  if (!isHome) {
    aboutLink = (
      <Tooltip title="About">
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setRedirectToHome(true)}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <a
          className={classes.headerLink}
          onClick={() => setRedirectToHome(true)}
        >
          <Typography variant="h6">
            {title ? title : "Traffic Tracking"}
          </Typography>
        </a>
        {children}
        {aboutLink}
      </Toolbar>
    </AppBar>
  )
}
