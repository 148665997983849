const fetchURL = routeKey => {
  const suffix = routeKey ? "/" + routeKey : ""
  return (
    "https://traffic-tracker-rq3lhkq3va-ew.a.run.app/api/trackedRoutes" + suffix
  )
}

export const fetchTrackedRoutes = routeKey => {
  return new Promise((resolve, reject) => {
    console.log(`Fetching tracked route [${routeKey}]`)

    fetch(fetchURL(routeKey), {
      method: "GET",
      mode: "cors"
    }).then(response => {
      if (response.ok) {
        console.log("Load route succeeded")

        resolve(response.json())
      } else {
        console.log("Load route failed", response)
        reject()
      }
    })
  })
}
