import moment from "moment"

function prep(text) {
  return text.replace(/ /g, "+")
}

export function mapsHref(origin, destination) {
  return `https://www.google.com/maps/dir/${prep(origin)}/${prep(destination)}/`
}

// The Modified Julian Day number is the number of days since midnight on November 17, 1858 AD
// Pick a basis year that is a multiple of 400
// 31 Dec 1600 is -94188
// 31 Dec 2000 is 51909
const basisMJD = 51909
const basisYear = 2001

// on 1 Jan 2001 we have had one leap year (2000)
// on 1 Jan 2005 we have had two leap years... (2000, 2004)

export function julianDayForMoment(m) {
  const yearsSinceBasis = m.year() - basisYear

  return (
    yearsSinceBasis * 365 +
    Math.floor(yearsSinceBasis / 4) - // an extra day for all the leap years
    Math.floor(yearsSinceBasis / 100) +
    Math.floor(yearsSinceBasis / 400) +
    m.dayOfYear() +
    basisMJD
  )
}

export function momentForJulianDay(julianDay) {
  // how many days since the end of 2000
  const daysSinceBasis = julianDay - basisMJD
  // how many whole years
  const wholeYearsSinceBasis = Math.floor(daysSinceBasis / 365)
  const leapYears =
    Math.floor(wholeYearsSinceBasis / 4) -
    Math.floor(wholeYearsSinceBasis / 100) +
    Math.floor(wholeYearsSinceBasis / 400)

  const remainderDays = daysSinceBasis - wholeYearsSinceBasis * 365 - leapYears

  return moment([basisYear + wholeYearsSinceBasis]).add(
    remainderDays - 1,
    "days"
  )
}

export function makeLodashDayFilter(daysFilter) {
  return item => {
    switch (item.day) {
      case 0:
        return daysFilter.sun
      case 1:
        return daysFilter.mon
      case 2:
        return daysFilter.tue
      case 3:
        return daysFilter.wed
      case 4:
        return daysFilter.thu
      case 5:
        return daysFilter.fri
      case 6:
        return daysFilter.sat
    }
  }
}
