import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { fetchData } from "./FetchData"
import { Typography, LinearProgress } from "@material-ui/core"
import Header from "./Header"
import { fetchTrackedRoutes } from "./FetchTrackedRoutes"
import { TrackedRouteDataView } from "./TrackedRouteDataView"

export const TrackedRoute = ({ routeKey }) => {
  const [data, setData] = useState(undefined)
  const [trackedRoute, setTrackedRoute] = useState(undefined)

  useEffect(() => {
    fetchTrackedRoutes(routeKey).then(setTrackedRoute)
    fetchData(routeKey).then(setData)
  }, [])

  const onClickRefresh = () => {
    setData(undefined)
    setTrackedRoute(undefined)
    fetchTrackedRoutes(routeKey).then(setTrackedRoute)
    fetchData(routeKey, { forceRefresh: true }).then(setData)
  }

  if (data && trackedRoute) {
    return (
      <TrackedRouteDataView
        data={data}
        trackedRoute={trackedRoute}
        onClickRefresh={onClickRefresh}
      />
    )
  } else {
    return (
      <>
        <Header></Header>
        <Typography component="span">
          Fetching data. This can take a few seconds...
        </Typography>
        <LinearProgress />
      </>
    )
  }
}

TrackedRoute.propTypes = {
  routeKey: PropTypes.string
}
