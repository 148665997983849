import sortBy from "lodash/sortBy"
import moment from "moment"

const fetchURL = routeKey => {
  return "https://traffic-tracker-rq3lhkq3va-ew.a.run.app/api/data/" + routeKey
}

const sortByTimeStamp = data => {
  return sortBy(data, d => d.timestamp)
}

const saveToLocalStorage = (data, routeKey) => {
  window.localStorage.setItem("traffic-data-" + routeKey, JSON.stringify(data))
  window.localStorage.setItem(
    "last-fetch-time-" + routeKey,
    moment().toISOString()
  )
  return data
}

const loadFromLocalStorage = routeKey => {
  const dataStr = window.localStorage.getItem("traffic-data-" + routeKey)
  const lastFetchTimeStr = window.localStorage.getItem(
    "last-fetch-time-" + routeKey
  )

  if (dataStr && lastFetchTimeStr) {
    console.log("Found local storage data")
    return {
      data: JSON.parse(dataStr),
      lastFetchTime: lastFetchTimeStr
    }
  } else {
    return undefined
  }
}

function localDataIsRecent(localData) {
  const lastFetchM = moment(localData.lastFetchTime)
  const diffMins = moment().diff(lastFetchM, "minutes")

  if (isNaN(diffMins)) {
    console.log(`Broken timestamp [${lastFetchM}], reloading...`)
    return false
  }

  if (diffMins > 5) {
    console.log(`Local data is ${diffMins} mins old. Reloading...`)
    return false
  } else {
    console.log(`Local data is ${diffMins} mins old. Using...`)
    return true
  }
}

export const fetchData = (routeKey, options) => {
  const forceRefresh = Boolean(options ? options.forceRefresh : false)
  console.log(`Loading data, forceRefresh=${forceRefresh}...`)

  return new Promise((resolve, reject) => {
    const localData = loadFromLocalStorage(routeKey)

    if (localData && !forceRefresh && localDataIsRecent(localData)) {
      resolve(localData.data)
      return
    }

    console.log("Fetching from remote...")

    fetch(fetchURL(routeKey), {
      method: "GET",
      mode: "cors"
    }).then(response => {
      if (response.ok) {
        console.log("Load succeeded")

        resolve(
          response
            .json()
            .then(sortByTimeStamp)
            .then(saveToLocalStorage)
        )
      } else {
        console.log("Load failed", response)
        reject()
      }
    })
  })
}
