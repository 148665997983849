import React, { useState } from "react"
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  DiscreteColorLegend
} from "react-vis"
import forEach from "lodash/forEach"
import range from "lodash/range"

import "react-vis/dist/style.css"
import { sprintf } from "sprintf-js"
import "./Plotter.css"
import { makeLodashDayFilter } from "./helpers"

export default ({ width, data, processor, inboundOrOutbound, daysFilter }) => {
  const [highlightedSeries, setHighLightedSeries] = useState(undefined)

  const xTickValues = processor.ticks()

  const lineSeriesItems = []
  const legendItems = []

  forEach(
    processor.process(data, makeLodashDayFilter(daysFilter)),
    (seriesData, seriesValue) => {
      // Compute the x/y data we're going to render in the chart
      const xyData = processor.toXY(seriesData, inboundOrOutbound)

      // Set the colour to grey if we have a highlighted series AND this is NOT the highlighted one.
      // We want the highlighted one to be the only one that retains its normal colour.
      const colorForSeries =
        highlightedSeries && seriesValue != highlightedSeries
          ? "#ddd"
          : undefined

      // Build the LineSeries for this series, and the corresponding legend entry
      lineSeriesItems.push(
        <LineSeries data={xyData} key={seriesValue} color={colorForSeries} />
      )
      legendItems.push({
        title: processor.legendForValue(seriesValue),
        color: colorForSeries,
        seriesValue: seriesValue
      })
    }
  )

  // Prepare the y axis
  const yTickValues = range(0, 300, 5) // 5 minute intervals
  // This formatter accepts the y value, which is the journey time in minutes
  const yTickFormat = minutes => {
    const hours = Math.floor(minutes / 60)
    const minsRem = minutes % 60
    return sprintf("%u:%02u", hours, minsRem)
  }

  return (
    <XYPlot height={500} width={width}>
      <VerticalGridLines tickValues={xTickValues} />
      <HorizontalGridLines tickValues={yTickValues} />
      <XAxis
        hideLine
        title={processor.xAxisTitle()}
        tickValues={xTickValues}
        tickFormat={processor.tickFormat}
      />
      <YAxis
        title="Journey time"
        tickValues={yTickValues}
        tickFormat={yTickFormat}
      />
      {lineSeriesItems}
      <DiscreteColorLegend
        items={legendItems}
        onItemMouseEnter={item => setHighLightedSeries(item.seriesValue)}
        onItemMouseLeave={() => setHighLightedSeries(undefined)}
      />
    </XYPlot>
  )
}
