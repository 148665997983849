import React, { useEffect, useState } from "react"
import Header from "./Header"
import {
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import { fetchTrackedRoutes } from "./FetchTrackedRoutes"
import map from "lodash/map"

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px"
  },
  linkText: {
    color: theme.palette.primary.main
  }
}))

const ListItemLink = props => {
  return <ListItem button component="a" {...props} />
}

const TrackedRouteListItem = ({ trackedRoute }) => {
  const classes = useStyles({})
  return (
    <ListItemLink href={"/r/" + trackedRoute.routeKey}>
      <ListItemIcon>
        <SwapHorizIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.linkText}
        primary={trackedRoute.origin + " to " + trackedRoute.destination}
      />
    </ListItemLink>
  )
}

export const Home = () => {
  const classes = useStyles({})
  const [trackedRoutes, setTrackedRoutes] = useState([])
  useEffect(() => {
    fetchTrackedRoutes().then(setTrackedRoutes), []
  }, [])

  return (
    <>
      <Header isHome />
      <div className={classes.textContainer}>
        <Typography component="div">
          <p>
            This app monitors the Google-predicted driving time between pairs of
            locations.
          </p>
          <p>The following routes are currently tracked:</p>
        </Typography>
        <List component="nav">
          {map(trackedRoutes, t => (
            <TrackedRouteListItem key={t.routeKey} trackedRoute={t} />
          ))}
        </List>
        <Typography component="div">
          <p>
            New tracked routes can be added on request, however the Google
            Directions API is{" "}
            <a href="https://developers.google.com/maps/documentation/directions/usage-and-billing#directions-advanced">
              surprisingly pricey
            </a>
            , so I cannot allow them to be added via the app.
          </p>
          <p>
            The app was built partly because the data was useful to me, but also
            as a learning tool for the technologies involved:
          </p>
          <ul>
            <li>
              <a href="https://reactjs.org/">React</a> and{" "}
              <a href="https://material-ui.com/">Material UI</a> for the UI
              components.
            </li>
            <li>
              <a href="https://uber.github.io/react-vis/">React-Vis</a> for the
              charts.
            </li>
            <li>
              The{" "}
              <a href="https://developers.google.com/maps/documentation/directions/start">
                Google Directions API
              </a>
              , sampled at intervals to provide driving times that take traffic
              into account.
            </li>
            <li>
              <a href="https://cloud.google.com/firestore/">
                Google Cloud Firestore
              </a>{" "}
              to hold the data collected from the Directions API.
            </li>
            <li>
              <a href="https://cloud.google.com/run/">Google Cloud Run</a> to
              host the backend service in a low-cost way.
            </li>
            <li>
              <a href="https://cloud.google.com/cloud-build">
                Google Cloud Build
              </a>{" "}
              to provide a CI/CD pipeline.
            </li>
          </ul>
          <p>I will probably publish the code on GitHub at some point.</p>
          <p>Tim Gage, January 2020</p>
        </Typography>
      </div>
    </>
  )
}
