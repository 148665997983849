import moment from "moment"
import map from "lodash/map"
import groupBy from "lodash/groupBy"
import _filter from "lodash/filter"
import { julianDayForMoment, momentForJulianDay } from "./helpers"
import range from "lodash/range"

const secondsPerDay = 24 * 60 * 60
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

// 2018-01-01 was a Monday and has JD of 58119. This is week 0 day 1.
// This means 2018-01-08 was the start of week 1.
const mondayOffset = 58119

export default {
  process: (input, filter) => {
    var result = map(input, item => {
      const m = moment(item.timestamp)
      const julianDay = julianDayForMoment(m)
      const week = Math.floor((julianDay - mondayOffset) / 7)
      const dayOfWeek = (julianDay - mondayOffset) % 7
      // m.day() is sunday-based. This is currently monday-based...
      const localWeekSecond =
        dayOfWeek * secondsPerDay +
        m.hour() * 60 * 60 +
        m.minute() * 60 +
        m.second()
      return {
        ...item,
        localWeekSecond: localWeekSecond,
        week: week,
        day: m.day()
      }
    })

    if (filter) {
      result = _filter(result, filter)
    }

    return groupBy(result, i => i.week)
  },

  toXY: (input, inboundOrOutbound) => {
    return input
      .filter(item => item[inboundOrOutbound])
      .map(item => {
        return {
          x: item.localWeekSecond,
          y: item[inboundOrOutbound].durationInSeconds / 60
        }
      })
  },

  ticks: () => {
    // one per day
    return range(0, 7 * secondsPerDay, secondsPerDay)
  },

  tickFormat: (localSecondOfWeek, index, scale, tickTotal) => {
    const dayNumber = Math.floor(localSecondOfWeek / secondsPerDay)

    return "00:00 " + dayNames[dayNumber]
  },

  legendForValue: weekNumber => {
    const m = momentForJulianDay(weekNumber * 7 + mondayOffset)
    return "w/c " + m.format("ddd D MMM")
  },

  xAxisTitle: () => {
    return "Time of week"
  }
}
