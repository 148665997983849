import moment from "moment"
import { sprintf } from "sprintf-js"
import map from "lodash/map"
import _filter from "lodash/filter"
import groupBy from "lodash/groupBy"
import { julianDayForMoment, momentForJulianDay } from "./helpers"
import range from "lodash/range"

export default {
  process: (input, filter) => {
    var result = map(input, item => {
      let m = moment(item.timestamp)
      const localDaySecond = m.hour() * 60 * 60 + m.minute() * 60 + m.second()
      return {
        ...item,
        localDaySecond: localDaySecond,
        day: m.day(),
        julianDay: julianDayForMoment(m)
      }
    })

    if (filter) {
      result = _filter(result, filter)
    }

    return groupBy(result, i => i.julianDay)
  },

  toXY: (input, inboundOrOutbound) => {
    return input
      .filter(item => item[inboundOrOutbound])
      .map(item => {
        return {
          x: item.localDaySecond,
          y: item[inboundOrOutbound].durationInSeconds / 60
        }
      })
  },

  ticks: () => {
    // one every hour
    return range(0, 24 * 3600, 3600)
  },

  tickFormat: (secondOfDay, index, scale, tickTotal) => {
    const hourOfDay = Math.floor(secondOfDay / (60 * 60))
    const minOfDay = Math.floor((secondOfDay % (60 * 60)) / 60)
    return sprintf("%02u:%02u", hourOfDay, minOfDay)
  },

  legendForValue: value => {
    return momentForJulianDay(value).format("ddd D MMM")
  },

  xAxisTitle: () => {
    return "Time of day"
  }
}
