import React from "react"
import { TrackedRoute } from "./TrackedRoute"
import {
  BrowserRouter as Router,
  Switch,
  useParams,
  Route
} from "react-router-dom"
import { Home } from "./Home"

const PageForRoute = () => {
  let { routeKey } = useParams()

  return <TrackedRoute routeKey={routeKey} />
}

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/r/:routeKey">
          <PageForRoute />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
